var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{attrs:{"visible":_vm.visible,"close-on-click-modal":false,"show-close":true,"width":"1300px","custom-class":"model_dialog"},on:{"update:visible":function($event){_vm.visible=$event},"close":_vm.close_modal}},[_c('span',{attrs:{"slot":"header"},slot:"header"}),_c('el-tabs',{on:{"tab-click":_vm.tabClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":_vm.$t('fileType.vmodelBtn1'),"name":"first"}},[_c('div',{staticStyle:{"margin-bottom":"24px"}},[_c('el-row',[_c('el-col',{attrs:{"span":17}},[_c('canvas',{ref:"v_camvas1",staticClass:"v_camvas"}),_c('div',{staticClass:"v_wrap"},_vm._l((_vm.rect_array),function(item,index){return _c('div',{key:index,style:({
                    left: item.left + 'px',
                    top: item.top + 'px',
                  }),on:{"dragover":function($event){$event.preventDefault();return _vm.dragover(index)},"dragleave":function($event){$event.preventDefault();return _vm.dragover_leave.apply(null, arguments)}}},_vm._l((item.type),function(type,index1){return _c('div',{key:index1,staticClass:"file_type",attrs:{"draggable":""},on:{"dragstart":function($event){return _vm.dragstart(index)},"dragend":_vm.dragend_item}},[(type.icon.toString() !== '-1')?_c('span',[_c('i',{staticClass:"iconfont",class:'icon-a-' + type.icon.toString(),staticStyle:{"color":"rgb(49, 96, 137)"}}),_vm._v("   "+_vm._s(type.file_type)+" ")]):_c('span',[_vm._v(_vm._s(type.file_type.slice(0, 1))+"   "+_vm._s(type.file_type))])])}),0)}),0)]),_c('el-col',{attrs:{"span":6,"offset":1}},[_c('div',{staticClass:"file_type_wrap",on:{"dragover":function($event){$event.preventDefault();return _vm.dragover(999)},"dragleave":function($event){$event.preventDefault();return _vm.dragover_leave.apply(null, arguments)}}},_vm._l((_vm.file_list),function(item,index){return _c('div',{key:index,staticClass:"file_type",attrs:{"draggable":""},on:{"dragstart":function($event){$event.stopPropagation();return _vm.dragstart(index)},"dragend":_vm.dragend}},[(item.icon && item.icon.toString() !== '-1')?_c('span',[_c('i',{staticClass:"iconfont",class:'icon-a-' + item.icon.toString(),staticStyle:{"color":"rgb(49, 96, 137)"}}),_vm._v("   "+_vm._s(item.file_type)+" ")]):_c('span',[_vm._v(_vm._s(item.file_type.slice(0, 1))+"   "+_vm._s(item.file_type))])])}),0)])],1)],1)]),_c('el-tab-pane',{attrs:{"label":_vm.$t('fileType.vmodelBtn2'),"name":"second"}},[_c('div',{staticStyle:{"margin-bottom":"24px"}},[_c('el-row',[_c('el-col',{attrs:{"span":20}},[_c('canvas',{ref:"v_camvas2",staticClass:"v_camvas2"}),_c('div',{staticClass:"v_wrap2"},_vm._l((_vm.rect_array2),function(item,index){return _c('div',{key:index,style:({
                    left: item.left + 'px',
                    top: item.top + 'px',
                  }),on:{"dragover":function($event){$event.preventDefault();return _vm.dragover2(index)},"dragleave":function($event){$event.preventDefault();return _vm.dragover_leave2.apply(null, arguments)}}},_vm._l((item.type),function(type,index1){return _c('div',{key:index1,staticClass:"file_type",attrs:{"draggable":""},on:{"dragstart":function($event){return _vm.dragstart2(index)},"dragend":_vm.dragend_item2}},[(type.icon.toString() !== '-1')?_c('span',[_c('i',{staticClass:"iconfont",class:'icon-a-' + type.icon.toString(),staticStyle:{"color":"rgb(49, 96, 137)"}}),_vm._v("   "+_vm._s(type.file_type)+" ")]):_c('span',[_vm._v(_vm._s(type.file_type.slice(0, 1))+"   "+_vm._s(type.file_type))])])}),0)}),0)]),_c('el-col',{attrs:{"span":4}},[_c('div',{staticClass:"file_type_wrap2",on:{"dragover":function($event){$event.preventDefault();return _vm.dragover2(999)},"dragleave":function($event){$event.preventDefault();return _vm.dragover_leave2.apply(null, arguments)}}},_vm._l((_vm.file_list2),function(item,index){return _c('div',{key:index,staticClass:"file_type",attrs:{"draggable":""},on:{"dragstart":function($event){$event.stopPropagation();return _vm.dragstart2(index)},"dragend":_vm.dragend2}},[(item.icon && item.icon.toString() !== '-1')?_c('span',[_c('i',{staticClass:"iconfont",class:'icon-a-' + item.icon.toString(),staticStyle:{"color":"rgb(49, 96, 137)"}}),_vm._v("   "+_vm._s(item.file_type)+" ")]):_c('span',[_vm._v(_vm._s(item.file_type.slice(0, 1))+"   "+_vm._s(item.file_type))])])}),0)])],1)],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }