<template>
  <div class="mytype">
    <el-row type="flex" justify="end">
      <el-col :span="10" style="text-align: right">
        <el-button type="primary" size="small" @click="show_vmodel">
          <span>{{ $t("fileType.vmodelBtn") }}</span>
        </el-button>
        <el-button type="primary" size="small" @click="add_fileType_show">
          <i class="el-icon-plus" style="color: #fff; margin-right: 2px"></i>
          <span>{{ $t("fileType.addFileTypeBtn") }}</span>
        </el-button>
      </el-col>
    </el-row>
    <el-table
      v-loading="loading"
      height="78vh"
      :element-loading-text="$t('loading.loading1')"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.1)"
      :data="tableData"
      style="width: 100%; margin-top: 28px"
      class="clickable-rows"
      :header-cell-style="{
        color: '#383838',
      }"
      :cell-style="changeCellStyle"
      @selection-change="handleSelectionChange"
    >
      <el-table-column v-if="selectingMultiple" type="selection" min-width="15">
        <!--如点击了批量操作，勾选框会显示/隐藏-->
      </el-table-column>
      <el-table-column
        prop="file_type"
        :label="$t('fileType.fileTypeTable.type')"
        min-width="50%"
        cursor="pointer"
      >
        <template slot-scope="scope">
          <div class="mydrag" style="display: inline-block;width: 30px;cursor: pointer;">
            <i class="iconfont icon-a-huaban33" style="font-weight: 400;color: #606266;"/>
          </div>
          <span
            v-if="flag != scope.row.fileTypeId"
            style="font-size: middle"
            :class="{ is_bug: scope.row.bug, is_testcase: scope.row.testCase }"
            @click="edit(scope.row)"
            >{{ scope.row.file_type }}</span
          >
          <input
            v-else
            ref="input_panel"
            v-model="input_name"
            type="text"
            @keyup.enter="away"
            @blur="away"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="file_icon"
        :label="$t('fileType.fileTypeTable.icon')"
        min-width="55%"
      >
        <template slot-scope="scope">
          <el-dropdown
            trigger="hover"
            @command="
              ($event) => {
                iconClick($event, scope.row);
              }
            "
          >
            <div class="icon_Box">
              <span
                v-if="scope.row.icon == '-1'"
                style="color: rgb(38, 50, 129); font-weight: 700"
              >
                {{ scope.row.file_type.slice(0, 1) }}
              </span>
              <i
                v-else
                class="iconfont"
                :class="'icon-a-' + scope.row.icon.slice(0, 1)"
                style="color: rgb(49, 96, 137)"
              >
              </i>
              <i class="el-icon-arrow-down"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="-1" :v-model="-1">
                <div>
                  <span style="color: rgb(38, 50, 129); font-weight: 700">
                    {{ scope.row.file_type.slice(0, 1) }}
                  </span>
                </div>
              </el-dropdown-item>
              <el-dropdown-item
                v-for="n in 10"
                :key="n - 1"
                :command="n - 1"
                :v-model="n - 1"
              >
                <div>
                  <span>
                    <i
                      class="iconfont"
                      :class="'icon-a-' + (n - 1).toString()"
                      style="color: rgb(49, 96, 137)"
                    />
                  </span>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
      <el-table-column
        prop="file_layer"
        :label="$t('fileType.fileTypeTable.layer')"
        min-width="55%"
      >
        <template slot-scope="scope">
          <!-- scope.row 包含表格里当前行的所有数据 -->
          &nbsp;&nbsp;{{ scope.row.file_layer }}
        </template>
      </el-table-column>

      <el-table-column :label="$t('btn.optionBtn')" min-width="10%">
        <template slot-scope="scope">
          <el-dropdown style="cursor: pointer;">
            <span class="el-dropdown-link" style="color: #84aace">
              <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
              &nbsp;&nbsp;<i
                class="iconfont icon-a-216gengduo-shuxiang"
                style="color: rgb(48, 100, 143)"
              />
              &nbsp;&nbsp;
            </span>
            <el-dropdown-menu slot="dropdown">
              <div class="operate_button" @click="toSettingPage(scope.row)">
                <i><i class="iconfont icon-edit" />&nbsp;&nbsp;&nbsp;</i
                >{{ $t("btn.editBtn") }}&nbsp;&nbsp;&nbsp;
              </div>

              <div class="operate_button" @click="cloneOnly([scope.row])">
                <i><i class="iconfont icon-copy" />&nbsp;&nbsp;&nbsp;</i
                >{{ $t("btn.copyBtn") }}&nbsp;&nbsp;&nbsp;
              </div>
              <div class="operate_button" @click="showPermission(scope.row)">
                <i
                  ><i
                    class="iconfont icon-a-permission1"
                  />&nbsp;&nbsp;&nbsp;</i
                >{{ $t("menu.permission") }}&nbsp;&nbsp;&nbsp;
              </div>
              <div class="operate_button" @click="upgradeMechanism(scope.row)">
                <i><i class="iconfont icon-shengji" />&nbsp;&nbsp;&nbsp;</i
                >{{ $t("fileType.upgradeMechanism") }}&nbsp;&nbsp;&nbsp;
              </div>
              <div class="operate_button" @click="deleteOnly(scope.row)">
                <i><i class="iconfont icon-delete" />&nbsp;&nbsp;&nbsp;</i
                >{{ $t("btn.deleteBtn") }}&nbsp;&nbsp;&nbsp;
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
      
    <el-dialog
      :visible.sync="add_fileType_dialog"
      class="dialogClass"
      width="25%"
      label-width
      style="text-align: left"
      :title="$t('fileType.addFileTypeBtn')"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <el-form
        ref="ruleForm"
        :rules="rules"
        label-position="left"
        :model="add_fileType_form"
        label-width="200px"
      >
        <el-form-item prop="file_name" label-width="120px">
          <template slot="label">
            <span>{{ $t("fileType.addFileTypeForm.name") }}</span>
          </template>
          <el-input
            v-model="add_fileType_form.file_name"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item prop="is_bug" :style="form_item_style">
          <template slot="label">
            <span>{{ $t("fileType.addFileTypeForm.bug") }}</span>

            <el-popover
              placement="bottom-start"
              width="250"
              popper-class="wrap-rules"
              trigger="hover"
              :content="$t('fileType.addFileTypeForm.pop')"
            >
              <i
                slot="reference"
                class="iconfont icon-help"
                style="margin-left: 5px; transform: scale(1.2)"
              ></i>
            </el-popover>
          </template>

          <el-radio-group
            v-model="add_fileType_form.is_bug"
            style="margin-left: 25px"
          >
            <el-radio
              label="1"
              :disabled="add_fileType_form.radio_choose == '1'"
              >{{ $t("fileType.addFileTypeForm.yes") }}</el-radio
            >
            <el-radio label="0">{{
              $t("fileType.addFileTypeForm.no")
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="radio_choose" :style="form_item_style">
          <template slot="label">
            <span>{{ $t("fileType.addFileTypeForm.testcase") }}</span>

            <el-popover
              placement="bottom-start"
              width="250"
              popper-class="wrap-rules"
              trigger="hover"
              :content="$t('fileType.addFileTypeForm.pop2')"
            >
              <i
                slot="reference"
                class="iconfont icon-help"
                style="margin-left: 5px; transform: scale(1.2)"
              ></i>
            </el-popover>
          </template>

          <el-radio-group
            v-model="add_fileType_form.radio_choose"
            style="margin-left: 25px"
          >
            <el-radio label="1" :disabled="add_fileType_form.is_bug == '1'">{{
              $t("fileType.addFileTypeForm.yes")
            }}</el-radio>
            <el-radio label="0">{{
              $t("fileType.addFileTypeForm.no")
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="add_filetype_cancel()">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="add_fileType()">{{
          $t("btn.newBtn")
        }}</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="upgrade_mechanism_outerdialog"
      class="upgradeDialogClass"
      label-width
      style="text-align: left"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <el-dialog
        width="40%"
        :title="$t('fileType.upgradeMechanism')"
        :visible.sync="upgrade_mechanism_innerdialog"
        :close-on-click-modal="false"
        @closed="innderClosed"
        append-to-body
      >
        <div
          class="addRulesBox"
          style="text-align: left; line-height: 56px; word-break: break-word"
        >
          {{ $t("fileType.p1") }}&nbsp;
          <el-select
            v-model="addMechanism.status"
            filterable
            multiple
            collapse-tags
            :placeholder="$t('placeholder.dropdown')"
            style="min-width: 260px"
          >
            <el-option
              v-for="item in fTypeStatusAllList"
              :key="item.statusId"
              :label="item.name"
              :value="item.statusId"
            >
            </el-option>
          </el-select>
          &nbsp;, {{ $t("fileType.p2") }}&nbsp;<el-input
            type="number"
            v-model="addMechanism.duration"
            style="width: 100px"
          ></el-input>
          &nbsp;{{ $t("fileType.p3") }}, {{ $t("fileType.p4") }}&nbsp;
          <span>
            <el-select
              v-model="tempUAndGList"
              multiple
              filterable
              collapse-tags
              :placeholder="$t('placeholder.dropdown')"
              style="min-width: 260px"
            >
              <el-option-group
                v-for="(group, index) in groupandUser"
                :key="group.label"
                :label="group.label"
              >
                <el-option
                  v-for="user in group.options"
                  :key="user.value"
                  :label="user.label"
                  :value="user.value + index"
                >
                </el-option>
              </el-option-group>
            </el-select>
          </span>
          &nbsp;{{ $t("fileType.p5") }}
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="upgrade_mechanism_innerdialog = false">{{
            $t("btn.cancelBtn")
          }}</el-button>
          <el-button
            :disabled="
              !tempUAndGList.length ||
              !addMechanism.duration ||
              !addMechanism.status.length
            "
            type="primary"
            @click="submitMechanism"
            >{{ $t("btn.confirmBtn") }}</el-button
          >
        </span>
      </el-dialog>
      <div slot="title">
        <span
          style="font-size: 18px !important; font-weight: 700; color: #303133"
        >
          {{ $t("fileType.upgradeMechanism") }}
        </span>
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('fileType.p6')"
          placement="top-start"
        >
          <i class="el-icon-question"></i>
        </el-tooltip>
      </div>
      <el-table
        :data="upgradeMechanismData"
        style="width: 100%"
        v-loading="tableLoading"
      >
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column
          class-name="upgradeCol"
          property="contentShow"
          :label="$t('fileType.ruleDescription')"
          min-width="85%"
        ></el-table-column>
        <el-table-column :label="$t('fileType.enableOrNot')" min-width="20%">
          <template slot-scope="scope">
            <el-switch
              v-model="upgradeMechanismData[scope.$index].enabled"
              @change="enabledChange(scope.$index)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column min-width="10%">
          <template slot-scope="scope">
            <div
              style="display: flex; align-items: center; height: 30px"
              @click="upgradeMechanismDelete(scope.$index, scope.row)"
            >
              <i
                class="iconfont icon-narrow"
                style="color: red; font-size: 25px; cursor: pointer"
              ></i>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <div style="margin: 15px 0 15px 0">
        <i
          class="iconfont icon-add"
          style="color: rgb(48, 100, 143); font-size: 25px; cursor: pointer"
          @click="upgradeMechanismAdd"
        ></i>
      </div>
    </el-dialog>
    <el-dialog
      :title="$t('review.tip11')"
      :visible.sync="deleteVisible"
      :close-on-click-modal="false"
      :show-close="true"
      width="25%"
      append-to-body
    >
      <div style="display: flex">
        <i
          style="font-size: 20px; color: rgb(255, 195, 0)"
          class="iconfont icon-jingshi-tianchong"
        ></i>
        <span style="float: left; text-align: start; padding-left: 10px">{{
          $t("fileType.deleteInfo")
        }}</span>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="delete_layer">{{
          $t("addMyColla.table.title10")
        }}</el-button>
        <el-button @click="deleteVisible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('menu.permissionSettings')"
      :visible.sync="permissionDialog"
      :close-on-click-modal="false"
      :show-close="true"
      width="25%"
      append-to-body
    >
      <div>
        <el-table
          :data="permissionTableData"
          height="400px"
          style="width: 100%"
        >
          <el-table-column
            prop="name"
            show-overflow-tooltip
            :label="$t('fileType.permission.table.label1')"
          >
          </el-table-column>
          <el-table-column
            prop="2"
            :label="$t('fileType.permission.table.label2')"
          >
            <template slot-scope="scope">
              <el-checkbox
                v-model="scope.row.NONE"
                @change="
                  (value) => {
                    permissionChange(value, scope.row, 'NONE');
                  }
                "
              ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            prop="3"
            :label="$t('fileType.permission.table.label3')"
          >
            <template slot-scope="scope">
              <el-checkbox
                v-model="scope.row.READ"
                @change="
                  (value) => {
                    permissionChange(value, scope.row, 'READ');
                  }
                "
              ></el-checkbox>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="permissionDialog = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="setPermission">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </span>
    </el-dialog>
    <vmodel-config
      v-if="vmodel_show"
      ref="vmodel"
      :table-data="tableData"
      @close_vmodel="close_vmodel"
    ></vmodel-config>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  delete_or_recover_user_bin,
  rename_cover,
} from "@/network/home/index.js";
import {
  get_filetype_List,
  filetype_copy,
  file_type_delete,
  filetype_modify,
  file_type_create,
  getStatusDurationRuleByProjectId,
  CreateStatusDurationRule,
  DeleteStatusDurationRule,
  EnableStatusDurationRule,
  DisableStatusDurationRule,
  get_filetype_detail,
  getJobAuths,
  setJobAuths,
  deleteJobAuths,
  changeFileTypeOrder,
} from "@/network/fileType/index.js";

import { get_workflow_resource } from "@/network/workflow/index.js";
import { get_jobAuthorities } from "@/network/home/index.js";
import vmson from "@/utils/vmson";
import vmodelConfig from "../vmodelConfig";
import Sortable from "sortablejs";
export default {
  name: "HomeBinVue",
  components: { vmodelConfig },
  inject: ["reload"],
  data() {
    return {
      vmodel_show: false,
      rules: {
        file_name: [
          {
            required: true,
            message: this.$t("placeholder.notNull"),
            trigger: "blur",
          },
        ],
      },
      form_item_style: {
        marginBottom: "0px",
      },
      add_fileType_form: {
        file_name: "",
        radio_choose: "0",
        is_bug: "0",
      },
      flag_name: "",
      flag: "", //控制输入框的显示
      input_name: "", //输入框的文件类型新名称
      icon_list: [],
      tableData: [],
      dragTableData: [], // 用来记录拖拽后的顺序
      activeIndex: "/home/setting/file_type/work_flow",
      selectingMultiple: false,
      selected: [],
      rsData: [],
      add_fileType_dialog: false,
      upgrade_mechanism_outerdialog: false,
      upgrade_mechanism_innerdialog: false,
      upgradeMechanismData: [
        {
          name: "lzl",
        },
      ],
      tempUAndGList: [],
      addMechanism: {
        status: [],
        duration: "",
        userList: [],
        groupList: [],
      },
      upgradeType: "",
      groupandUser: [
        {
          label: this.$t("knowledge.user"),
          options: [],
        },
        {
          label: this.$t("knowledge.userGroup"),
          options: [],
        },
      ],
      statusAllList: {},
      fTypeStatusAllList: {},
      tableLoading: false,
      deleteVisible: false,
      deleteRow: "",
      // 权限配置弹窗相关
      permissionDialog: false,
      permissionTableData: [],
      currentFileTypeId: "",
    };
  },
  computed: {
    ...mapGetters(["user_list", "user_group_list"]),
  },
  watch: {
    "addMechanism.duration": {
      handler(newVal, oldVal) {
        this.addMechanism.duration = Math.floor(newVal);
      },
    },
  },
  mounted() {
    this.setallGroup();
    if (this.$store && this.$store.getters && this.$store.getters.status) {
      this.statusAllList = Object.assign(this.$store.getters.status);
    }
    vmson.$on("filetype_update", () => {
      this.loading = true;
      this.get_filetype_data();
    });
    const that_ = this
    const tbody = document.querySelector('.el-table__body tbody');
    Sortable.create(tbody, {
      handle: '.mydrag',
      animation: 150,
      onEnd({ newIndex, oldIndex }) {
        if(oldIndex>newIndex){
          while(oldIndex>newIndex){
            [that_.dragTableData[oldIndex],that_.dragTableData[oldIndex-1]] = [that_.dragTableData[oldIndex-1],that_.dragTableData[oldIndex]]
            oldIndex--;
          }
        }else{
          while(oldIndex<newIndex){
            [that_.dragTableData[oldIndex],that_.dragTableData[oldIndex+1]] = [that_.dragTableData[oldIndex+1],that_.dragTableData[oldIndex]]
            oldIndex++;
          }
        }

        let list = that_.dragTableData.map((item)=>{
          return item.fileTypeId
        })
        let params = {
          projectId: that_.get_pid(),
          list
        }
        changeFileTypeOrder(params).then(res=>{
          that_.tableData = that_.dragTableData
        })
      }
    })
  },
  created() {
    this.get_filetype_data();
  },
  methods: {
    // 打开权限配置弹窗
    permissionChange(value, row, type) {
      row.NONE = false;
      row.READ = false;
      row[type] = value;
      if (value) {
        const params = {
          projectId: this.get_pid(),
          fileTypeId: this.currentFileTypeId,
          data: {
            jobId: row.jobId,
            fileTypeAuthType: type,
          },
        };
        setJobAuths(params).then((res) => {});
      } else {
        const params = {
          projectId: this.get_pid(),
          fileTypeId: this.currentFileTypeId,
          jobId: row.jobId,
        };
        deleteJobAuths(params).then((res) => {});
      }
    },
    setPermission() {
      this.permissionDialog = false;
    },
    showPermission(row) {
      this.currentFileTypeId = row.fileTypeId;
      get_jobAuthorities(this.get_pid()).then((jobs) => {
        getJobAuths({
          projectId: this.get_pid(),
          fileTypeId: row.fileTypeId,
        }).then((permissions) => {
          this.permissionTableData = [];
          jobs.forEach((item) => {
            const obj = {
              name: item.name,
              jobId: item.jobId,
              NONE: false,
              READ: false,
            };
            permissions.forEach((permission) => {
              if (permission.jobId === item.jobId) {
                if (permission.fileTypeAuthType === "READ") {
                  obj.READ = true;
                } else if (permission.fileTypeAuthType === "NONE") {
                  obj.NONE = true;
                }
              }
            });
            this.permissionTableData.push(obj);
          });
          this.permissionDialog = true;
        });
      });
    },
    // v模型配置窗口展示
    show_vmodel() {
      this.vmodel_show = true;
    },
    close_vmodel() {
      this.vmodel_show = false;
    },
    /*  新建文件类型取消 */
    add_filetype_cancel() {
      this.add_fileType_dialog = false;
      this.add_fileType_form.file_name = "";
      this.add_fileType_form.radio_choose = "";
    },
    /*  打开新建文件类型对话框 */
    add_fileType_show() {
      this.add_fileType_dialog = true;
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields();
      });
    },
    /*  新建文件类型 */
    add_fileType() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let file_data = {
            name: this.add_fileType_form.file_name,
            testCase: this.add_fileType_form.radio_choose == "1",
            bug: this.add_fileType_form.is_bug == "1",
          };
          file_type_create(this.get_pid(), file_data).then((res) => {
            this.add_fileType_form.file_name = "";
            this.add_fileType_form.radio_choose = "";
            this.get_filetype_data();
            this.add_fileType_dialog = false;
          });
        }
      });
    },

    away(e) {
      let cur_id = this.flag;
      let value = e.target.value;
      this.flag = "";
      if (value == "") {
        this.flag = "";
      } else if (this.flag_name == value) {
        return;
      } else if (cur_id != "") {
        filetype_modify(this.get_pid(), cur_id, { name: value }).then((res) => {
          this.get_filetype_data();
          this.flag = "";
          this.$message({
            type: "success",
            message: this.$t("canvas.messages.renameSuccess"),
          });
          this.input_name = "";
        });
      }
    },

    edit(row) {
      this.flag_name = row.file_type;
      this.input_name = row.file_type;
      this.flag = row.fileTypeId;
      setTimeout(() => {
        this.$refs.input_panel.focus();
      }, 220);
    },
    cloneOnly(row) {
      this.loading = true;
      filetype_copy(this.get_pid(), row[0].fileTypeId).then((res) => {
        this.reload();
        this.$message({
          type: "success",
          message: this.$t("AI.tip14"),
          duration: "5000",
        });

        this.get_filetype_data();
      });
    },
    iconClick(item, row) {
      filetype_modify(this.get_pid(), row.fileTypeId, {
        icon: item,
      }).then((res) => {
        this.get_filetype_data();
      });
    },
    get_filetype_data() {
      this.loading = true;
      get_filetype_List(this.get_pid()).then((res) => {
        this.tableData = [];
        for (let i in res) {
          let obj = {
            file_type: res[i].name,
            file_layer: res[i].layerCount,
            fileTypeId: res[i].fileTypeId,
            icon: res[i].icon,
            bug: res[i].bug,
            testCase: res[i].testCase,
          };
          this.tableData.push(obj);
        }
        this.dragTableData = this.tableData
        this.loading = false;
      });
    },
    toSettingPage(row) {
      this.$store.commit("SET_PAGE_LAYER", row.file_layer);
      this.$store.commit("SET_PAGE_TYPEID", row.fileTypeId);
      this.$store.commit("SET_PAGE_NAME", row.file_type);
      this.$router.push(
        `/${this.get_pid()}/home/setting/file_type/template_set`
      );
    },
    changeCellStyle(row, column, rowIndex, columnIndex) {
      if (row.column.label === "ID") {
        return "color: #FFC300;font-weight: 700;"; // 修改的样式
      } else {
        return "color:#636363; font-weight: 700;";
      }
    },
    // 筛选文件类型
    handleCommand(command) {
      //根据key进行判断
      if (command == this.$t("addMyColla.need")) {
        //页面信息条件筛选过滤
      }
    },
    ifRename(node) {
      let str =
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let result = "";
      for (let i = 6; i > 0; --i) {
        result += str[Math.floor(Math.random() * str.length)];
      }

      rename_cover(
        this.$store.getters.email,
        node.file_name,
        node.file_name + "-" + this.$t("canvas.messages.ano") + result,
        node.fid
      ).then((res) => {
        this.reload();
      });
      return 1;
    },
    postDeleteOrRecover(action) {
      //发送请求参数通知后端用户的操作，从而更新数据库
      delete_or_recover_user_bin(
        this.$store.getters.email,
        this.selected,
        action
      )
        .then((res) => {
          if (res !== undefined && res.files !== undefined) {
            /* if(action==1){
                   var temp=[];
                   for(var i in this.selected){
                     for(var j in res.files){
                       if(this.selected[i].file_name==res.files[j].file_name){
                         temp.push(res.files[j]);

                       }

                     }
                   };
                   var i=0;
                   while(i<temp.length){

                     if(this.ifRename(temp[i])==1){
                       i++;
                     }
                   }
                }
*/
          }
          this.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectMultipleClicked() {
      this.selectingMultiple = !this.selectingMultiple;
    },
    handleSelectionChange(selection) {
      this.selected = selection;
    },
    deleteMultiple(row) {
      this.$confirm(this.$t("fileType.fileTypeTable.options.deleteForm.info"), {
        cancelButtonClass: 'myCancelButton',
        confirmButtonClass: 'myConfirmButton',
        cancelButtonText: this.$t("addMyColla.table.title10"),
        confirmButtonText: this.$t("btn.cancelBtn"),
        distinguishCancelAndClose: true,
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "info",
            message: this.$t("fileType.message.deleteInfo"),
          });
        })
        .catch(action => {
            if(action === 'cancel'){
              //通知后端从数据库中删除
              const params = {
                projectId: this.get_pid(),
                fileTypeId: row.fileTypeId,
              };
              file_type_delete(params).then((res) => {
                this.reload();
                this.$message({
                  type: "success",
                  message: this.$t("fileType.message.deleteSuccess"),
                });
              });
              // this.postDeleteOrRecover(2);
            }else if(action === 'close'){
              this.$message({
                type: "info",
                message: this.$t("fileType.message.deleteInfo"),
              });
            }
            
          }
        );
    },
    recoverMultiple() {
      if (this.selected.length > 0) {
        //通知后端从数据库中标记文件为正常文件
        this.postDeleteOrRecover(1);
        this.$message({
          type: "success",
          message: this.$t("bin.message.revertSuccess"),
        });
      }
    },
    deleteOnly(row) {
      this.handleSelectionChange(row);
      this.deleteMultiple(row);
    },
    recoverOnly(row) {
      this.handleSelectionChange(row);
      this.recoverMultiple();
    },
    async getStatusDurationRuleByProjectId() {
      this.tableLoading = true;
      this.upgradeMechanismData = [];
      const params = {
        projectId: this.get_pid(),
      };
      const res = await getStatusDurationRuleByProjectId(params);
      if (res) {
        this.upgradeMechanismData = res.filter((ite) => {
          return ite.fileTypeId == this.upgradeType;
        });
        this.upgradeMechanismData.forEach((item) => {
          let contStatus = [];
          item.statuses.forEach((s) => {
            if (this.statusAllList[s]) {
              contStatus.push(this.statusAllList[s].name);
            }
          });
          let temp = [...item.userList, ...item.groupList];
          let contUser = [];
          temp.forEach((it) => {
            for (let o of this.groupandUser) {
              for (let i of o.options) {
                if (i.value == it) {
                  contUser.push(i.label);
                  break;
                }
              }
            }
          });
          item.contentShow =
            `${this.$t("fileType.p1")} ${contStatus.join("、")}` +
            `, ${this.$t("fileType.p2")} ${parseFloat(
              item.duration / 60 / 24
            ).toFixed(1)} ` +
            `${this.$t("fileType.p3")},` +
            " " +
            `${this.$t("fileType.p4")} ${contUser.join("、")}` +
            " " +
            `${this.$t("fileType.p5")}`;
        });
      }
      this.tableLoading = false;
    },
    async upgradeMechanism(row) {
      this.upgradeType = row.fileTypeId;
      this.upgrade_mechanism_outerdialog = true;
      this.getStatusDurationRuleByProjectId();
      get_filetype_detail(this.get_pid(), this.upgradeType).then(
        async (res) => {
          const fTypeLayerlist = res.fileTypeLayerList;
          let currentTypeStatusAll = [];
          const currentTypeStatusAllPromises = fTypeLayerlist.map((item) =>
            get_workflow_resource(
              this.get_pid(),
              this.upgradeType,
              item.layerNumber
            ).then((res) => res.nodes)
          );
          let temp = new Object();
          currentTypeStatusAll = await Promise.all(
            currentTypeStatusAllPromises
          );
          currentTypeStatusAll = currentTypeStatusAll.flat(Infinity);
          currentTypeStatusAll.forEach((i) => {
            if (this.statusAllList[i.id].statusCategory != "DONE") {
              temp[i.id] = this.statusAllList[i.id];
            }
          });
          this.fTypeStatusAllList = temp;
        }
      );
    },
    upgradeMechanismDelete(row, done) {
      this.deleteVisible = true;
      this.deleteRow = row;
    },
    delete_layer() {
      let params = {
        projectId: this.get_pid(),
        ruleUuid: this.upgradeMechanismData[this.deleteRow].rulesUuid,
      };
      DeleteStatusDurationRule(params).then((res) => {
        this.getStatusDurationRuleByProjectId();
        this.deleteVisible = false;
      });
    },
    upgradeMechanismAdd() {
      this.upgrade_mechanism_innerdialog = true;
    },
    submitMechanism() {
      this.tempUAndGList.map((taga) => {
        if (taga.substr(-1) == 0) {
          this.addMechanism.userList.push(taga.substr(0, taga.length - 1));
        } else {
          this.addMechanism.groupList.push(taga.substr(0, taga.length - 1));
        }
      });
      let duration = parseInt(parseFloat(this.addMechanism.duration) * 1440);

      let params = {
        projectId: this.get_pid(),
        data: {
          FileTypeId: this.upgradeType,
          Statuses: this.addMechanism.status,
          Duration: duration,
          UserList: this.addMechanism.userList,
          GroupList: this.addMechanism.groupList,
        },
      };
      CreateStatusDurationRule(params).then((res) => {
        this.getStatusDurationRuleByProjectId();
        this.tempUAndGList = [];
        this.addMechanism = {
          status: [],
          duration: "",
          userList: [],
          groupList: [],
        };
        this.upgrade_mechanism_innerdialog = false;
      });
    },
    setallGroup() {
      const group = Object.values(this.user_group_list);
      const user = Object.values(this.user_list);
      const filteredArray = user.filter((obj) => obj.deleted == 0);
      filteredArray.map((item, index) => {
        this.groupandUser[0].options.push({
          value: item.accountId,
          label: item.nickname,
        });
      });
      group.map((item, index) => {
        this.groupandUser[1].options.push({
          value: item.groupId,
          label: item.groupName,
        });
      });
    },
    enabledChange(index) {
      let params = {
        projectId: this.get_pid(),
        ruleUuid: this.upgradeMechanismData[index].rulesUuid,
      };
      if (this.upgradeMechanismData[index].enabled) {
        EnableStatusDurationRule(params);
      } else {
        DisableStatusDurationRule(params);
      }
    },
    innderClosed() {
      this.tempUAndGList = [];
      this.addMechanism = {
        status: [],
        duration: "",
        userList: [],
        groupList: [],
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.is_bug {
  position: relative;
  &::after {
    content: "B";
    color: #000;
    position: absolute;
    font-size: 12px;
    text-align: center;
    width: 18px; /*no*/
    height: 18px; /*no*/
    line-height: 18px; /*no*/
    border-radius: 50%;
    background: #fac5c5;
  }
}
.is_testcase {
  position: relative;
  &::after {
    content: "T";
    color: #000;
    position: absolute;
    font-size: 12px;
    text-align: center;
    width: 18px; /*no*/
    height: 18px; /*no*/
    line-height: 18px; /*no*/
    border-radius: 50%;
    background: #ccc;
  }
}
.icon_Box {
  display: flex;
  width: 35px;
  justify-content: space-between;
  align-items: center;
}
</style>

<style scoped>
form.el-form.el-form--label-left {
  padding-bottom: 0px;
}
.add_font {
  margin-right: 2px;
}
.add_button {
  min-width: 132px;
  height: 28px;
  padding: 0 6px;
  cursor: pointer;
  color: #003b73;
  border: #003b73 1px solid;
  border-radius: 4px;
  font-size: 16px;
}

.clickable-rows::before {
  height: 0px;
}
.clickable-rows {
  cursor: default;
  width: 100%;
  height: 74vh;
  overflow: auto;
  background: white;
}

::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
.operate_button {
  display: block;
  /* width: 60px; */
  margin: 15px 20px;
  text-align: left;
  cursor: pointer;
  color: #656667;
}
.operate_button:hover {
  color: #46b4f4;
}
/deep/.el-tooltip__popper {
  display: none;
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 70vh;
}
.i {
  cursor: pointer;
}
::v-deep .upgradeCol .cell {
  word-break: break-word !important;
}
</style>
<style>
.wrap-rules {
  word-break: break-word;
}
</style>
<style scoped>
::v-deep .addRulesBox .el-select__tags {
  flex-wrap: nowrap !important;
  white-space: nowrap !important;
  max-width: 230px !important;
}
::v-deep .el-select__tags-text {
  display: inline-block !important;
  max-width: 100px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
::v-deep .el-tag--info:first-child {
  display: flex !important;
  align-items: center !important;
}
::v-deep .addRulesBox .el-select__tags > span {
  display: flex !important;
  overflow: hidden !important;
}
</style>
<style>
.myCancelButton{
  color: #FFF !important;
  background-color: #F56C6C !important;
  border-color: #F56C6C !important;
}
.myCancelButton:hover {
    background: rgb(247, 137, 137) !important;
}
.myConfirmButton{
    background: #FFF !important;
    border: 1px solid #DCDFE6 !important;
    color: #606266 !important;
}
.myConfirmButton:hover{
    color: #409EFF !important;
    border-color: #c6e2ff !important;
    background: #FFF !important;
}
</style>
