<template>
  <div>
    <el-dialog
      :visible.sync="visible"
      :close-on-click-modal="false"
      :show-close="true"
      width="1300px"
      custom-class="model_dialog"
      @close="close_modal"
    >
      <span slot="header"> </span>
      <el-tabs v-model="activeName" @tab-click="tabClick">
        <el-tab-pane :label="$t('fileType.vmodelBtn1')" name="first">
          <div style="margin-bottom: 24px">
            <el-row>
              <el-col :span="17">
                <canvas ref="v_camvas1" class="v_camvas"> </canvas>
                <div class="v_wrap">
                  <div
                    v-for="(item, index) in rect_array"
                    :key="index"
                    :style="{
                      left: item.left + 'px',
                      top: item.top + 'px',
                    }"
                    @dragover.prevent="dragover(index)"
                    @dragleave.prevent="dragover_leave"
                  >
                    <div
                      v-for="(type, index1) in item.type"
                      :key="index1"
                      class="file_type"
                      draggable
                      @dragstart="dragstart(index)"
                      @dragend="dragend_item"
                    >
                      <span v-if="type.icon.toString() !== '-1'">
                        <i
                          class="iconfont"
                          :class="'icon-a-' + type.icon.toString()"
                          style="color: rgb(49, 96, 137)"
                        />&nbsp;&nbsp;
                        {{ type.file_type }}
                      </span>
                      <span v-else
                        >{{ type.file_type.slice(0, 1) }}&nbsp;&nbsp;
                        {{ type.file_type }}</span
                      >
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col :span="6" :offset="1">
                <div
                  class="file_type_wrap"
                  @dragover.prevent="dragover(999)"
                  @dragleave.prevent="dragover_leave"
                >
                  <div
                    v-for="(item, index) in file_list"
                    :key="index"
                    class="file_type"
                    draggable
                    @dragstart.stop="dragstart(index)"
                    @dragend="dragend"
                  >
                    <span v-if="item.icon && item.icon.toString() !== '-1'">
                      <i
                        class="iconfont"
                        :class="'icon-a-' + item.icon.toString()"
                        style="color: rgb(49, 96, 137)"
                      />&nbsp;&nbsp;
                      {{ item.file_type }}
                    </span>
                    <span v-else
                      >{{ item.file_type.slice(0, 1) }}&nbsp;&nbsp;
                      {{ item.file_type }}</span
                    >
                  </div>
                </div>
              </el-col>
            </el-row>
          </div></el-tab-pane
        >
        <el-tab-pane :label="$t('fileType.vmodelBtn2')" name="second">
          <div style="margin-bottom: 24px">
            <el-row>
              <el-col :span="20">
                <canvas ref="v_camvas2" class="v_camvas2"> </canvas>
                <div class="v_wrap2">
                  <div
                    v-for="(item, index) in rect_array2"
                    :key="index"
                    :style="{
                      left: item.left + 'px',
                      top: item.top + 'px',
                    }"
                    @dragover.prevent="dragover2(index)"
                    @dragleave.prevent="dragover_leave2"
                  >
                    <div
                      v-for="(type, index1) in item.type"
                      :key="index1"
                      class="file_type"
                      draggable
                      @dragstart="dragstart2(index)"
                      @dragend="dragend_item2"
                    >
                      <span v-if="type.icon.toString() !== '-1'">
                        <i
                          class="iconfont"
                          :class="'icon-a-' + type.icon.toString()"
                          style="color: rgb(49, 96, 137)"
                        />&nbsp;&nbsp;
                        {{ type.file_type }}
                      </span>
                      <span v-else
                        >{{ type.file_type.slice(0, 1) }}&nbsp;&nbsp;
                        {{ type.file_type }}</span
                      >
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col :span="4">
                <div
                  class="file_type_wrap2"
                  @dragover.prevent="dragover2(999)"
                  @dragleave.prevent="dragover_leave2"
                >
                  <div
                    v-for="(item, index) in file_list2"
                    :key="index"
                    class="file_type"
                    draggable
                    @dragstart.stop="dragstart2(index)"
                    @dragend="dragend2"
                  >
                    <span v-if="item.icon && item.icon.toString() !== '-1'">
                      <i
                        class="iconfont"
                        :class="'icon-a-' + item.icon.toString()"
                        style="color: rgb(49, 96, 137)"
                      />&nbsp;&nbsp;
                      {{ item.file_type }}
                    </span>
                    <span v-else
                      >{{ item.file_type.slice(0, 1) }}&nbsp;&nbsp;
                      {{ item.file_type }}</span
                    >
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
import {
  getvmodelFileTypeRelation,
  setvmodelFileTypeRelation,
} from "@/network/vmodel/index.js";
import {
  getwmodelFileTypeRelation,
  setwmodelFileTypeRelation,
} from "@/network/wmodel/index.js";
export default {
  props: {
    tableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      activeName: "first",
      position_map: {
        SYS1: 0,
        SYS2: 1,
        SYS3: 2,
        SYS4: 9,
        SYS5: 10,
        SWE1: 3,
        SWE2: 4,
        SWE3: 5,
        SWE4: 6,
        SWE5: 7,
        SWE6: 8,
      },
      position_map2: {
        SWE1: 5,
        SWE2: 6,
        SWE3: 7,
        SWE4: 10,
        SWE5: 9,
        SWE6: 8,
        SYS1: 0,
        SYS2: 1,
        SYS3: 2,
        SYS4: 4,
        SYS5: 3,
        HWE1: 11,
        HWE2: 12,
        HWE2_1: 13,
        HWE3: 15,
        HWE4: 14,
        HWE2_2: 16,
      },
      rect_array: [
        {
          left: 0,
          top: 0,
          name: "SYS1",
        },
        {
          left: 50,
          top: 100,
          name: "SYS2",
        },
        {
          left: 100,
          top: 200,
          name: "SYS3",
        },
        {
          left: 150,
          top: 300,
          name: "SWE1",
        },
        {
          left: 200,
          top: 400,
          name: "SWE2",
        },
        {
          left: 250,
          top: 500,
          name: "SWE3",
        },
        {
          left: 500,
          top: 500,
          name: "SWE4",
        },
        {
          left: 550,
          top: 400,
          name: "SWE5",
        },
        {
          left: 600,
          top: 300,
          name: "SWE6",
        },
        {
          left: 650,
          top: 200,
          name: "SYS4",
        },
        {
          left: 700,
          top: 100,
          name: "SYS5",
        },
      ],
      rect_array2: [
        {
          left: 280,
          top: 0,
          name: "SYS1",
        },
        {
          left: 290,
          top: 100,
          name: "SYS2",
        },
        {
          left: 300,
          top: 200,
          name: "SYS3",
        },
        {
          left: 570,
          top: 100,
          name: "SYS5",
        },
        {
          left: 560,
          top: 200,
          name: "SYS4",
        },
        {
          left: 40,
          top: 340,
          name: "SWE1",
        },
        {
          left: 50,
          top: 440,
          name: "SWE2",
        },
        {
          left: 60,
          top: 540,
          name: "SWE3",
        },
        {
          left: 300,
          top: 340,
          name: "SWE6",
        },
        {
          left: 290,
          top: 440,
          name: "SWE5",
        },
        {
          left: 280,
          top: 540,
          name: "SWE4",
        },
        {
          left: 560,
          top: 340,
          name: "HWE1",
        },
        {
          left: 570,
          top: 440,
          name: "HWE2",
        },
        {
          left: 580,
          top: 540,
          name: "HWE2_1",
        },
        {
          left: 820,
          top: 340,
          name: "HWE4",
        },
        {
          left: 810,
          top: 440,
          name: "HWE3",
        },
        {
          left: 800,
          top: 540,
          name: "HWE2_2",
        },
      ],
      file_list: [],
      file_list2: [],
      visible: true,
      dragitem_index: null,
      dragover_index: null,
      dragitem_index2: null,
      dragover_index2: null,
    };
  },
  watch: {},
  mounted() {
    this.file_list = this.tableData.concat([]);
    this.file_list2 = this.tableData.concat([]);
    this.getvmodelFileTypeRelation();
    this.getwmodelFileTypeRelation();
  },
  methods: {
    // 画小圆点
    drawdot(ctx, x, y) {
      ctx.setLineDash([]);

      ctx.beginPath();
      // 画圆（完整的圆为圆点）
      ctx.arc(x, y, 4, 0, Math.PI * 2);
      // 关闭路径
      ctx.closePath();
      // 进行填充绘制
      ctx.fill();
    },
    // 划线
    drawLine(ctx, item) {
      // // 开始路径
      ctx.setLineDash([3, 3]);
      ctx.beginPath();
      // 移动到起始点
      ctx.moveTo(item[0], item[1]); // 起始点坐标
      // 绘制二次贝塞尔曲线
      let controlX = 0;
      var endX = item[2]; // 结束点的 x 坐标
      var endY = item[3]; // 结束点的 y 坐标
      if (item[4] === "left") {
        controlX = item[0] - 60; // 控制点的 x 坐标
        var controlY = item[1] + (item[3] - item[1]) / 2 + 60; // 控制点的 y 坐标
        ctx.quadraticCurveTo(controlX, controlY, endX, endY);
      } else if (item[4] === "right") {
        controlX = item[2] + 60; // 控制点的 x 坐标
        var controlY = item[1] + (item[3] - item[1]) / 2 - 60; // 控制点的 y 坐标
        ctx.quadraticCurveTo(controlX, controlY, endX, endY);
      } else if (item[4] === "left-to-right") {
        controlX = item[0] + (item[2] - item[0]) / 2; // 控制点的 x 坐标
        var controlY = item[1]; // 控制点的 y 坐标
        var controlX2 = item[0] + (item[2] - item[0]) / 2; // 控制点的 x2 坐标
        var controlY2 = item[3]; // 控制点的 y2 坐标
        ctx.bezierCurveTo(controlX, controlY, controlX2, controlY2, endX, endY);
      }
      // 描边路径
      ctx.stroke();
    },
    // 初始化线条
    initCanvas1() {
      const cvs = this.$refs["v_camvas1"]; // 获取元素
      const ctx = cvs.getContext("2d"); // 创建上下文 context 对象 便于理解也可以取名叫 pen
      cvs.width = cvs.offsetWidth;
      cvs.height = cvs.offsetHeight;
      ctx.clearRect(0, 0, cvs.width, cvs.height);
      ctx.strokeStyle = "#98B8D9";
      ctx.fillStyle = "#98B8D9";
      const dotList = [
        [50, 140],
        [150, 340],
        [250, 540],
        // [300, 240],
        // [400, 440],
      ];
      const LineList = [
        [50, 140, 150, 340, "left"],
        [150, 340, 250, 540, "left"],
        // [300, 240, 400, 440, "right"],
      ];
      dotList.forEach((item) => {
        this.drawdot(ctx, item[0], item[1]);
      });
      LineList.forEach((item) => {
        this.drawLine(ctx, item);
      });
    },
    // 初始化线条
    initCanvas2() {
      const cvs = this.$refs["v_camvas2"]; // 获取元素
      const ctx = cvs.getContext("2d"); // 创建上下文 context 对象 便于理解也可以取名叫 pen
      cvs.width = cvs.offsetWidth;
      cvs.height = cvs.offsetHeight;
      ctx.clearRect(0, 0, cvs.width, cvs.height);
      ctx.strokeStyle = "#98B8D9";
      ctx.fillStyle = "#98B8D9";
      const dotList = [
        [40, 380],
        [60, 580],
        [200, 380],
        [210, 480],
        [290, 140],
        [300, 240],
        [450, 140],
        [460, 240],
        [560, 380],
        [570, 480],
        [580, 580],
      ];
      const LineList = [
        [40, 380, 60, 580, "left"],
        [200, 380, 290, 140, "left-to-right"],
        [200, 380, 300, 240, "left-to-right"],
        [210, 480, 300, 240, "left-to-right"],
        [450, 140, 560, 380, "left-to-right"],
        [460, 240, 570, 480, "left-to-right"],
        [460, 240, 560, 380, "left-to-right"],
        [560, 380, 580, 580, "left"],
      ];
      dotList.forEach((item) => {
        this.drawdot(ctx, item[0], item[1]);
      });
      LineList.forEach((item) => {
        this.drawLine(ctx, item);
      });
    },
    // // tabs点击事件
    tabClick(tab) {
      if (tab.name === "second") {
        this.$nextTick(() => {
          this.initCanvas2();
        });
      }
    },
    getvmodelFileTypeRelation() {
      const params = {
        projectId: this.get_pid(),
        vmodelProcess: [
          "SWE1",
          "SWE2",
          "SWE3",
          "SWE4",
          "SWE5",
          "SWE6",
          "SYS1",
          "SYS2",
          "SYS3",
          "SYS4",
          "SYS5",
        ],
      };
      getvmodelFileTypeRelation(params).then((res) => {
        res.forEach((item) => {
          this.$set(
            this.rect_array[this.position_map[item.vmodelProcess]],
            "type",
            this.file_list.filter((file_type) => {
              return item.fileTypeId === file_type.fileTypeId;
            })
          );
          this.file_list = this.file_list.filter((file_type) => {
            return item.fileTypeId !== file_type.fileTypeId;
          });
        });
        this.initCanvas1();
      });
    },
    getwmodelFileTypeRelation() {
      const params = {
        projectId: this.get_pid(),
        wmodelProcess: [
          "SWE1",
          "SWE2",
          "SWE3",
          "SWE4",
          "SWE5",
          "SWE6",
          "SYS1",
          "SYS2",
          "SYS3",
          "SYS4",
          "SYS5",
          "HWE1",
          "HWE2",
          "HWE2_1",
          "HWE3",
          "HWE4",
          "HWE2_2",
        ],
      };
      getwmodelFileTypeRelation(params).then((res) => {
        res.forEach((item) => {
          this.$set(
            this.rect_array2[this.position_map2[item.wmodelProcess]],
            "type",
            this.file_list2.filter((file_type) => {
              return item.fileTypeId === file_type.fileTypeId;
            })
          );
          this.file_list2 = this.file_list2.filter((file_type) => {
            return item.fileTypeId !== file_type.fileTypeId;
          });
        });
      });
    },
    setvmodelFileTypeRelation() {
      const params = {
        projectId: this.get_pid(),
        data: [],
      };
      this.rect_array.forEach((item) => {
        if (item.type && item.type.length) {
          const obj = {
            fileTypeId: item.type[0].fileTypeId,
            vmodelProcess: item.name,
          };
          params.data.push(obj);
        }
      });
      setvmodelFileTypeRelation(params).then((res) => {});
    },
    setwmodelFileTypeRelation() {
      const params = {
        projectId: this.get_pid(),
        data: [],
      };
      this.rect_array2.forEach((item) => {
        if (item.type && item.type.length) {
          const obj = {
            fileTypeId: item.type[0].fileTypeId,
            wmodelProcess: item.name,
          };
          params.data.push(obj);
        }
      });
      setwmodelFileTypeRelation(params).then((res) => {});
    },
    dragstart(index) {
      this.dragitem_index = index;
    },
    dragend_item() {
      if (this.dragover_index !== null && this.dragover_index !== 999) {
        if (
          this.rect_array[this.dragover_index].type &&
          this.rect_array[this.dragover_index].type.length
        ) {
          const a = this.rect_array[this.dragitem_index].type.splice(0, 1)[0];
          const b = this.rect_array[this.dragover_index].type.splice(0, 1)[0];
          this.rect_array[this.dragover_index].type = [a];
          this.rect_array[this.dragitem_index].type = [b];
        } else {
          const a = this.rect_array[this.dragitem_index].type.splice(0, 1)[0];
          this.rect_array[this.dragover_index].type = [a];
        }
      } else {
        this.file_list.push(
          this.rect_array[this.dragitem_index].type.splice(0, 1)[0]
        );
      }
      this.rect_array.push();
      (this.dragitem_index = null), (this.dragover_index = null);
      this.setvmodelFileTypeRelation();
    },
    dragend() {
      if (this.dragover_index !== null) {
        if (
          this.rect_array[this.dragover_index].type &&
          this.rect_array[this.dragover_index].type.length
        ) {
          this.file_list.push(
            this.rect_array[this.dragover_index].type.splice(
              0,
              1,
              this.file_list.splice(this.dragitem_index, 1)[0]
            )[0]
          );
        } else {
          this.rect_array[this.dragover_index].type = [
            this.file_list.splice(this.dragitem_index, 1)[0],
          ];
        }
      }
      (this.dragitem_index = null), (this.dragover_index = null);
      this.setvmodelFileTypeRelation();
    },
    dragover(index) {
      this.dragover_index = index;
    },
    dragover_leave() {
      this.dragover_index = null;
    },
    close_modal() {
      this.$emit("close_vmodel");
    },
    // W模型相关
    dragstart2(index) {
      this.dragitem_index2 = index;
    },
    dragend_item2() {
      if (this.dragover_index2 !== null && this.dragover_index2 !== 999) {
        if (
          this.rect_array2[this.dragover_index2].type &&
          this.rect_array2[this.dragover_index2].type.length
        ) {
          const a = this.rect_array2[this.dragitem_index2].type.splice(0, 1)[0];
          const b = this.rect_array2[this.dragover_index2].type.splice(0, 1)[0];
          this.rect_array2[this.dragover_index2].type = [a];
          this.rect_array2[this.dragitem_index2].type = [b];
        } else {
          const a = this.rect_array2[this.dragitem_index2].type.splice(0, 1)[0];
          this.rect_array2[this.dragover_index2].type = [a];
        }
      } else {
        this.file_list2.push(
          this.rect_array2[this.dragitem_index2].type.splice(0, 1)[0]
        );
      }
      this.rect_array2.push();
      (this.dragitem_index2 = null), (this.dragover_index2 = null);
      this.setwmodelFileTypeRelation();
    },
    dragend2() {
      if (this.dragover_index2 !== null) {
        if (
          this.rect_array2[this.dragover_index2].type &&
          this.rect_array2[this.dragover_index2].type.length
        ) {
          this.file_list2.push(
            this.rect_array2[this.dragover_index2].type.splice(
              0,
              1,
              this.file_list2.splice(this.dragitem_index2, 1)[0]
            )[0]
          );
        } else {
          this.rect_array2[this.dragover_index2].type = [
            this.file_list2.splice(this.dragitem_index2, 1)[0],
          ];
        }
      }
      (this.dragitem_index2 = null), (this.dragover_index2 = null);
      this.setwmodelFileTypeRelation();
    },
    dragover2(index) {
      this.dragover_index2 = index;
    },
    dragover_leave2() {
      this.dragover_index2 = null;
    },
  },
};
</script>

<style lang="scss">
.model_dialog {
  .el-dialog__header {
    padding: 6px 0 !important;
  }
}
.v_camvas {
  width: 100%;
  height: 600px;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
}
.v_camvas2 {
  width: 100%;
  height: 660px;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
}
.v_wrap {
  width: 100%;
  height: 600px;
  position: relative;
  user-select: none;
  background: transparent;
  & > div {
    position: absolute;
    width: 200px;
    height: 80px;
    border-radius: 8px;
    background: #e6e6e6;
    border: 2px dashed rgba(226, 241, 255, 1);
    box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.2);
    & > div {
      width: 100%;
      height: 100%;
      margin: 0;
      box-shadow: 3px 3px 4px rgba(48, 100, 143, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.v_wrap2 {
  width: 100%;
  height: 660px;
  position: relative;
  user-select: none;
  & > div {
    position: absolute;
    width: 160px;
    height: 80px;
    border-radius: 8px;
    background: #e6e6e6;
    border: 2px dashed rgba(226, 241, 255, 1);
    box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.2);
    & > div {
      width: 100%;
      height: 100%;
      margin: 0;
      box-shadow: 3px 3px 4px rgba(48, 100, 143, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.file_type_wrap {
  border: 2px solid rgba(226, 241, 255, 1);
  background-color: rgba(226, 241, 255, 0.4);
  border-radius: 6px;
  padding: 10px;
  height: 600px;
  overflow-y: auto;
  text-align: left;
  user-select: none;
}
.file_type_wrap2 {
  border: 2px solid rgba(226, 241, 255, 1);
  background-color: rgba(226, 241, 255, 0.4);
  border-radius: 6px;
  padding: 10px;
  height: 660px;
  overflow-y: auto;
  text-align: left;
  user-select: none;
}
.file_type {
  display: inline-block;
  padding: 6px 10px;
  margin: 6px;
  color: rgb(48, 100, 143);
  border: 0.0052rem solid #2a82e4;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
}
</style>
